import { Common } from '~/models/common';
import type { Variables } from '~/models/variables';

const collection = 'variables';

export const variablesService = {
	fetch: async (): Promise<Variables.Model[]> => {
		const { getItems } = useDirectusItems();

		return await getItems<Variables.Model>({
			collection,
			params: {
				filter: {
					status: Common.Status.PUBLISHED,
				},
			},
		});
	},
};
