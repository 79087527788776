import { domainsService } from '~/services/domains';
import { Domains } from '~/models/domains';
import { Common } from '~/models/common';

export const useContactsState = () => ({
	contactsState: useState<Domains.Model[]>('contactsState', () => []),
	contactsLoaded: useState<boolean>('contactsLoaded', () => false),
	contactsPending: useState<boolean>('contactsPending', () => true),
	contactsCurrentId: useState<number>('contactsCurrentId', () => Domains.modelState.id),
});

export const useContacts = () => {
	const cookieDomain = useCookie('selectedDomain');
	const { contactsState, contactsLoaded, contactsPending, contactsCurrentId } =
		useContactsState();

	/**
	 * Fetch contacts
	 */
	const fetchContacts = async () => {
		if (contactsLoaded.value) return;

		contactsPending.value = true;

		contactsState.value = await domainsService.fetch();

		contactsCurrentId.value = cookieDomain.value
			? Number(cookieDomain.value)
			: contactsState.value.find(c => c.isDefault)?.id || Domains.modelState.id;

		contactsLoaded.value = true;
		contactsPending.value = false;
	};

	/**
	 * Refresh contacts
	 */
	const refreshContacts = async () => {
		contactsLoaded.value = false;

		await fetchContacts();
	};

	/**
	 * Contacts list
	 */
	const contactsList = computed(() =>
		contactsState.value.reduce((acc, domain) => {
			acc.push(domain);
			return acc;
		}, [] as Domains.Contact[])
	);

	/**
	 * Contact select list
	 */
	const contactsSelectList = computed(() =>
		contactsList.value.reduce(
			(acc, cur) => (cur.name ? [...acc, { label: cur.name, value: cur.id }] : [...acc]),
			[] as Common.SelectOption[]
		)
	);

	/**
	 * Contact
	 */
	const contacts = computed(
		() => contactsList.value.find(c => c.id === contactsCurrentId.value) || Domains.modelState
	);

	/**
	 * Watch contactsCurrentId and set cookie
	 */
	watch(contactsCurrentId, () => {
		cookieDomain.value = contactsCurrentId.value.toString();
	});

	return {
		contactsState,
		contactsPending,
		contactsCurrentId,
		fetchContacts,
		refreshContacts,

		contactsSelectList,
		contactsList,
		contacts,
	};
};
