import type { Variables } from '~/models/variables';
import { variablesService } from '~/services/variables';

export const useVariablesState = () => ({
	variablesState: useState<Variables.Model[]>('variablesState', () => []),
	variablesLoaded: useState<boolean>('variablesLoaded', () => false),
	variablesPending: useState<boolean>('variablesPending', () => true),
});

export const useVariables = () => {
	const { variablesState, variablesLoaded, variablesPending } = useVariablesState();

	/**
	 * Fetch variables
	 */
	const fetchVariables = async () => {
		if (variablesLoaded.value) return;

		variablesPending.value = true;

		variablesState.value = await variablesService.fetch();

		variablesLoaded.value = true;
		variablesPending.value = false;
	};

	/**
	 * Refresh variables
	 */
	const refreshVariables = async () => {
		variablesLoaded.value = false;

		await fetchVariables();
	};

	/**
	 * Variables list
	 */
	const variablesList = computed(() =>
		variablesState.value.reduce(
			(acc, { name, value }) => {
				if (name && value) {
					acc[name] = value;
				}
				return acc;
			},
			{} as Record<string, string | undefined>
		)
	);

	return {
		variablesState,
		variablesPending,
		variablesList,

		fetchVariables,
		refreshVariables,
	};
};
