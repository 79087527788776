import { Common } from '~/models/common';
import type { Domains } from '~/models/domains';

const collection = 'domains';

export const domainsService = {
	fetch: async (): Promise<Domains.Model[]> => {
		const { getItems } = useDirectusItems();

		return await getItems<Domains.Model>({
			collection,
			params: {
				filter: {
					status: Common.Status.PUBLISHED,
				},
				fields: ['*', 'to.url'],
			},
		});
	},
};
