import { Common } from '~/models/common';

export namespace Domains {
	export type isDefault = boolean;
	export type Name = string | null;
	export type Address = string | null;
	export type Email = string | null;
	export type Phone = string | null;
	export type Subway = string | null;
	export type logoTitle = string | null;
	export type WorkTime = string | null;
	export type Whatsapp = string | null;
	export type WhatsappText = string | null;
	export type Telegram = string | null;
	export type TelegramText = string | null;
	export type MapLink = string | null;
	export type MapText = string | null;
	export type Map = string | null;

	export type Contact = {
		id: Common.Id;
		isDefault: isDefault;
		name: Name;
		address: Address;
		addressShort: Address;
		email: Email;
		phone: Phone;
		subway: Subway;
		workTime: WorkTime;
		logoTitle: logoTitle;
		whatsapp: Whatsapp;
		whatsappText: WhatsappText;
		telegram: Telegram;
		telegramText: TelegramText;
		mapLink: MapLink;
		mapText: MapText;
		map: Map;
	};

	export type Model = Common.Model & Contact;

	export const modelState: Model = {
		...Common.modelState,
		id: 0,
		isDefault: false,
		name: null,
		address: null,
		addressShort: null,
		email: null,
		phone: null,
		subway: null,
		workTime: null,
		logoTitle: null,
		whatsapp: null,
		whatsappText: null,
		telegram: null,
		telegramText: null,
		mapLink: null,
		mapText: null,
		map: null,
	};
}
