type Breakpoint =
	| 'mobile'
	| 'mobileMedium'
	| 'mobileWide'
	| 'tablet'
	| 'desktop'
	| 'desktopMedium'
	| 'desktopWide';

export const usePlatform = () => {
	const { $viewport } = useNuxtApp();

	const breakpoint = $viewport.breakpoint;

	const isMobile = computed(() => $viewport.breakpoint.value === 'mobile');
	const isMobileMedium = computed(() => $viewport.breakpoint.value === 'mobileMedium');
	const isMobileWide = computed(() => $viewport.breakpoint.value === 'mobileWide');
	const isTablet = computed(() => $viewport.breakpoint.value === 'tablet');
	const isDesktop = computed(() => $viewport.breakpoint.value === 'desktop');
	const isDesktopMedium = computed(() => $viewport.breakpoint.value === 'desktopMedium');
	const isDesktopWide = computed(() => $viewport.breakpoint.value === 'desktopWide');

	const lessThan = (breakpoint: Breakpoint) => $viewport.isLessThan(breakpoint);
	const greaterThan = (breakpoint: Breakpoint) => $viewport.isGreaterThan(breakpoint);
	const greaterOrEquals = (breakpoint: Breakpoint) => $viewport.isGreaterOrEquals(breakpoint);

	return {
		breakpoint,

		isMobile,
		isMobileMedium,
		isMobileWide,
		isTablet,
		isDesktop,
		isDesktopMedium,
		isDesktopWide,

		lessThan,
		greaterThan,
		greaterOrEquals,
	};
};
